<template>
  <b-card-code no-body title="Asset List">

    <b-modal id="modal-commodity-price" size="lg" title="Price History">

      <commodity-price-history-chart v-if="price_history_slug != null" :commodity_slug="price_history_slug"></commodity-price-history-chart>

      <template #modal-footer="{}">
      </template>
    </b-modal>

    <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

          <div class="col-md-6">
            <b-button @click="cleanupForm()" v-if="checkPermission('create commodity')" v-b-modal.form-commodity-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add Asset">
              <plus-circle-icon size="1x" class="custom-class"></plus-circle-icon> Add Asset
            </b-button>

            <b-modal id="form-commodity-modal" size="lg" :title="editSlug != null ? 'Edit Asset' : 'Add Asset'">
              <div class="form">

                <h5>Asset Information</h5>

                <div class="form-group">
                  <label for="">Name:</label>
                  <input type="text" class="form-control" v-model="formPayload.name" placeholder="Asset name">
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Main Image:</label><br>
                      <input type="file" id="file" ref="file" @change="changeCover()">
                    </div>
                  </div>
                  <div class="col-6">
                    <div v-if="formPayload.cover_url != null">
                      <small>Current Main Image:</small><br>
                      <img v-bind:src="formPayload.cover_url" style="max-width: 100%;">
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Product:</label>
                  <select class="form-control" v-model="formPayload.product">
                    <option>Payment of Ecosystem Services (PES)</option>
                    <option>Carbon Credit (CC)</option>
                    <optgroup label="Renewable Energy Certificate (REC)">
                      <option>Renewable Energy Certificate (REC)</option>
                      <option>IDREC - Solar Panel</option>
                      <option>IDREC - Wind</option>
                      <option>IDREC - Hydro</option>
                      <option>IDREC - Biomass</option>
                      <option>IDREC - Biogas</option>
                      <option>IDREC - Geothermal</option>
                      <option>IDREC - Cofired Fossil</option>
                      <option>IDREC - Nuclear</option>
                    </optgroup>
                    <option>Emission Market</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">Category:</label>
                  <select class="form-control" v-model="formPayload.category_id">
                    <option selected value="">-- Select Category --</option>
                    <template v-for="category in categories">
                      <option :key="category.slug" :value="category.id">{{ category.name }}</option>
                      <template v-for="child in category.childs">
                        <option :key="child.slug" :value="child.id">{{ category.name }} - {{ child.name }}</option>
                        <template v-for="subchild in child.childs">
                          <option :key="subchild.slug" :value="subchild.id">{{ category.name }} - {{ child.name }} - {{ subchild.name }}</option>
                        </template>
                      </template>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">SDG:</label>
                    <v-select
                      v-model="formPayload.sdg"
                      :options="sdgs"
                      :reduce="sdg => sdg"
                      id="sdg"
                      multiple
                  >
                  </v-select>
                </div>

                <div class="form-group">
                  <label for="">Type:</label>
                  <select class="form-control" v-model="formPayload.type">
                    <option v-for="(type, key) in types" :key="key" :value="key">{{ type }}</option>
                  </select>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Registry Asset Code:</label>
                      <input type="text" class="form-control" v-model="formPayload.epact_commodity_name" placeholder="Asset code in EPACT">
                      <small class="text-info">This field used to sync with Registry API</small>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Asset Code:</label>
                      <input type="text" class="form-control" v-model="formPayload.code" placeholder="Asset code in App">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Vintage:</label>
                      <input type="text" class="form-control" v-model="formPayload.metas.vintage" placeholder="Asset Vintage (example: 2024)">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">Country:</label>
                      <input type="text" class="form-control" v-model="formPayload.metas.country" placeholder="Asset Country">
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Asset Quality:</label>
                  <input type="text" class="form-control" v-model="formPayload.quality" placeholder="Asset quality">
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Asset Unit:</label>
                      <!-- <input type="text" class="form-control" v-model="formPayload.unit" placeholder="Commodity unit, example: Kg, Ton, Gram"> -->
                      <select class="form-control" v-model="formPayload.unit">
                        <option value="Kg">Kg</option>
                        <option value="Ton">Ton</option>
                        <option value="Gram">Gram</option>
                        <option value="MTon">MTon</option>
                        <option value="MWh">MWh</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="">Amount per Lot:</label>
                      <div class="input-group">
                        <input type="number" class="form-control" v-model="formPayload.amount_per_lot" placeholder="Amount per Lot">
                        <div class="input-group-append">
                          <span class="input-group-text">{{ formPayload.unit }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Description:</label>
                  <quill-editor
                    v-model="formPayload.description"
                    :options="editorOption"
                  >
                    <div
                      id="toolbar"
                      slot="toolbar"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold">
                        Bold
                      </button>
                      <button class="ql-italic">
                        Italic
                      </button>

                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>

                      <!-- Add subscript and superscript buttons -->
                      <button
                        class="ql-script"
                        value="sub"
                      />
                      <button
                        class="ql-script"
                        value="super"
                      />

                    </div>
                  </quill-editor>
                </div>

                <div v-if="isTerra">
                  <br>
                  <br>

                  <h5>Additional Information</h5>

                  <div class="row">

                    <div v-for="(label, key) in additionalFieldFile" class="col-6" :key="'value-' + key">
                      <div class="form-group">
                        <label :for="key">{{ label }}</label><br>
                        <input type="file" :id="key" :ref="key" @change="changeFile($event.target.files, key)">
                        <div v-if="formPayload[key + '_url'] != null">
                          <small>Current {{ label }}:</small><br>
                          <a :href="formPayload[key + '_url']" target="_blank">Open File</a>
                        </div>
                      </div>
                    </div>

                  </div>

                  <br>
                  <br>

                  <!-- Properties -->
                  <h5>Properties</h5>
                  <button class="btn btn-sm btn-success" @click="addProperty()">
                    <feather-icon
                        icon="PlusIcon"
                    /> Add Property
                  </button>
                  <table class="table table-hover table-borderless">
                    <tr v-for="property, index in formPayload.properties" :key="index">
                      <td width="45%" class="pl-0">
                        <div class="form-group  mt-0 mb-0">
                          <label :for="'label_' + index">Title:</label>
                          <input type="text" class="form-control" :id="'label_' + index" v-model="formPayload.properties[index].label" placeholder="Property title..." required>
                        </div>
                      </td>
                      <td width="45%" class="pl-0">
                        <div class="form-group  mt-0 mb-0">
                          <label :for="'value_' + index">Content:</label>
                          <input type="text" class="form-control" :id="'value_' + index" v-model="formPayload.properties[index].value" placeholder="Property content..." required>
                        </div>
                      </td>
                      <td class="text-right pr-0">
                        <button class="btn btn-sm btn-danger ml-1 mt-1 mb-0" @click="removeProperty(index)">
                          <feather-icon
                              icon="XIcon"
                          />
                        </button>
                      </td>
                    </tr>
                  </table>
                  <br>
                  <br>

                  <!-- Images -->
                  <h5>Images</h5>
                  <button class="btn btn-sm btn-success" @click="addImage()">
                    <feather-icon
                        icon="PlusIcon"
                    /> Add Image
                  </button>
                  <div class="row pt-1">
                    <div class="col-md-4 mb-1 text-center" v-for="image, index in formPayload.images" :key="index">
                      <div v-if="typeof image === 'object' && image != null && image.hasOwnProperty('file_url')">
                        <label :for="'label_' + index">Image {{ index + 1 }}:</label><br>
                        <img :src="image.file_url" style="max-width: 100%;">
                      </div>
                      <div class="form-group  mt-0 mb-0" v-else>
                        <label :for="'label_' + index">Image {{ index + 1 }}:</label>
                        <input type="file" class="form-control" :id="'label_' + index" @change="changeImageItem($event.target.files, index)" required>
                      </div>
                      <br>
                      <button class="btn btn-sm btn-danger ml-1 mt-1 mb-0" @click="removeImage(index)">
                        <feather-icon
                            icon="XIcon"
                        />
                      </button>
                    </div>
                  </div>
                  <br>
                  <br>

                  <!-- Location -->
                  <div>
                    <h5>Location</h5>
                    <GeolocationSelectorMap v-model="selectedLocation" :defaultLocation="currentLocation" :key="1" />
                  </div>

                </div>
                </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button v-if="editSlug == null" variant="success" @click="createCommodity()" v-bind:disabled="isLoading">
                  Save Asset
                </b-button>
                <b-button v-else variant="success" @click="updateCommodity()" v-bind:disabled="isLoading">
                  Save Asset
                </b-button>
              </template>
            </b-modal>
          </div>

          <div class="col-md-6">
            <b-button v-if="checkPermission('import commodity price')" v-b-modal.form-commodity-import-modal class="btn btn-primary btn-sm mb-1 float-right" data-toggle="tooltip" data-placement="top" title="Add Asset">
              <feather-icon
                size="1x"
                :icon="'UploadCloudIcon'"
                :class="'font-medium-1 custom-class'"
              />
              Import Asset Price
            </b-button>

            <b-modal no-close-on-backdrop id="form-commodity-import-modal" size="lg" :title="'Import Asset Price'">
              <div class="form">

                <div class="form-group">
                  <label for="">File:</label><br>
                  <input type="file" id="fileImportPrice" ref="fileImportPrice" @change="changeImportPrice()">
                </div>

                <div v-if="isLoadingImport">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <button v-else class="btn btn-primary btn-sm" @click="importPrice()">Import Data</button>
                <br>
                <hr>
                <small>Download import format <span @click="downloadImportFormat()" class="text-primary">here</span></small>

              </div>
              <template #modal-footer="{}">
                <div></div>
              </template>
            </b-modal>
          </div>

          <div class="col-md-12">
              <div class="form-row mb-1">
                  <div class="col-md-3">
                      <select class="form-control form-control-sm" v-model="filter.category_id" @change="getData(currentPage)">
                        <option selected value="">-- Select Category --</option>
                        <template v-for="category in categories">
                          <option :key="category.slug" :value="category.id">{{ category.name }}</option>
                          <template v-for="child in category.childs">
                            <option :key="child.slug" :value="child.id">{{ category.name }} - {{ child.name }}</option>
                            <template v-for="subchild in child.childs">
                              <option :key="subchild.slug" :value="subchild.id">{{ category.name }} - {{ child.name }} - {{ subchild.name }}</option>
                            </template>
                          </template>
                        </template>
                      </select>
                  </div>
                  <div class="col-md-9 text-right">
                      <input type="text" v-model="filter.search" placeholder="Search name" class="form-control form-control-sm float-right" style="width: 200px;">
                  </div>
              </div>
          </div>
        </div>
    </div>

    <div class="table-responsive-sm">
      <table class="table b-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Category</th>
            <th>Previous Price</th>
            <th>Auction Price</th>
            <th>Amount per Lot</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="6" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.slug">
            <td>{{ item.name }}</td>
            <td class="text-capitalize">{{ types[item.type] }}</td>
            <td>{{ item.category != null ? item.category.name : '-' }}</td>
            <td>
              {{ item.yesterday_price == null ? '-' : item.yesterday_price }}
            </td>
            <td>
              {{ item.last_price }}
            </td>
            <td>{{ item.amount_per_lot + ' ' + item.unit }}</td>
            <td>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button @click="showPriceHistory(item)" class="btn btn-primary btn-sm">
                  <feather-icon
                    size="1x"
                    :icon="'ActivityIcon'"
                    :class="'font-medium-1 custom-class'"
                  />
                </button>
                <button class="btn btn-info btn-sm" v-if="checkPermission('update commodity')" @click="editCommodity(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit">
                  <edit-icon size="1x" class="custom-class"></edit-icon>
                </button>
                <button class="btn btn-danger btn-sm" v-if="checkPermission('delete commodity')" @click="deleteCommodity(item.slug)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Delete">
                  <trash-2-icon size="1x" class="custom-class"></trash-2-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="6" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BFormTimepicker, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { Trash2Icon, PlusCircleIcon, EditIcon } from 'vue-feather-icons'
import { quillEditor } from 'vue-quill-editor'
import FileSaver from 'file-saver'
import CommodityPriceHistoryChart from '@/views/components/CommodityPriceHistoryChart.vue'
import GeolocationSelectorMap from "../components/GeolocationSelectorMap"
import vSelect from 'vue-select'

export default {
  title () {
    return `List Asset`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    PlusCircleIcon,
    quillEditor,
    EditIcon,
    Trash2Icon,
    BFormTimepicker,
    BSpinner,
    CommodityPriceHistoryChart,
    GeolocationSelectorMap,
    vSelect
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.getData();
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
        sdgs: [
          "No Poverty",
          "Zero Hunger",
          "Good Health and Well-being",
          "Quality Education",
          "Gender Equality",
          "Clean Water and Sanitation",
          "Affordable and Clean Energy",
          "Decent Work and Economic Growth",
          "Industry, Innovation and Infrastructure",
          "Reduced Inequality",
          "Sustainable Cities and Communities",
          "Responsible Consumption and Production",
          "Climate Action",
          "Life Below Water",
          "Life on Land",
          "Peace and Justice Strong Institutions",
          "Partnerships to achieve the Goal",
        ],
        types: {
          premium: "Premium Offset Market",
          standard: "Standard Offset Market",
          emission_market: "Emission Market",
        },
        additionalFieldFile: {
          company_profile: 'Company Profile',
          project_design_document_standard: 'Project Design Document Standard',
          validation_and_verification_document_standard: 'Validation and Verification Document Standard',
          issuance_document: 'Issuance Document',
          other: 'Other',
        },
        price_history_slug: null,
        editorOption: {
          modules: {
            toolbar: '#toolbar',
          },
        },
        currentPage: 1,
        categories: [],
        result: {},
        filter: {
            category_id: '',
            search: null,
        },
        editSlug: null,
        formPayload: this.getInitalizeForm(),
        importPricePayload: {
          file: null,
        },
        isLoading: false,
        isLoadingImport: false,
        isTerra: process.env.IS_TERRA == 'true',
        selectedLocation: {},
        currentLocation: {
          lat: '-6.200000',
          lng: '106.816666'
        },
    }
  },
  created() {
    this.loadFilter();
    this.getData();
  },
  mounted() {
    var context = this
    this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      if (payload.type == 'commodity-transaction') {
        context.getData(context.currentPage || 1);
      }
    });
  },
  methods: {
    getInitalizeForm() {
      return {
        type: 'premium',
        category_id: '',
        epact_commodity_name: '',
        product: 'Payment of Ecosystem Services (PES)',
        cover: null,
        name: '',
        code: '',
        quality: '',
        unit: 'kg',
        amount_per_lot: 100,
        description: '',
        company_profile: null,
        project_design_document_standard: null,
        validation_and_verification_document_standard: null,
        issuance_document: null,
        other: null,
        properties: [
          this.getPropertyPayload()
        ],
        images: [
          this.getImagePayload()
        ],
        sdg: [],
        metas: {
          latitude: null,
          longitude: null,
          vintage: null,
          country: null,
          custodial_code: null,
        },
      };
    },
    getPropertyPayload() {
      return {
        label: '',
        value: '',
      };
    },
    addProperty() {
      this.formPayload.properties.push(
        this.getPropertyPayload()
      );
    },
    removeProperty(index) {
      this.formPayload.properties.splice(index, 1);
    },
    changeFile(fileList, key) {
      if (!fileList.length) return;
      this.formPayload[key] = fileList[0];
    },
    getImagePayload() {
      return null;
    },
    addImage() {
      this.formPayload.images.push(
        this.getImagePayload()
      );
    },
    removeImage(index) {
      this.formPayload.images.splice(index, 1);
    },
    changeImageItem(fileList, index) {
        if (!fileList.length) return;
        this.formPayload.images[index] = fileList[0];
    },
    loadFilter() {
        this.$http.get('/public/commodities/categories')
        .then(response => {
            this.categories = response.data.data
        })

        this.$http.get('/admin/miscellaneous/auction-seller-fee-payment-list')
        .then(response => {
          this.sellerFeePaymentOn = response.data.data
        })

        this.$http.get('/admin/miscellaneous/auction-buyer-fee-payment-list')
        .then(response => {
          this.buyerFeePaymentOn = response.data.data
        })

        this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc')
        .then(response => {
          this.users = response.data.data.data
        })

    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/commodities', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    changeCover() {
      this.formPayload.cover = this.$refs.file.files[0];
    },
    changeImportPrice() {
      this.importPricePayload.file = this.$refs.fileImportPrice.files[0];
    },
    cleanupForm() {
      this.editSlug = null;
      this.formPayload = this.getInitalizeForm()
    },
    showPriceHistory(item) {
      this.$bvModal.show('modal-commodity-price')
      this.price_history_slug = item.slug
    },
    editCommodity(item) {
      console.log(this.currentLocation)
      this.editSlug = item.slug
      this.formPayload = {
        category_id: item.category != null ? item.category.id : null,
        epact_commodity_name: item.epact_commodity_name,
        product: item.product,
        cover_url: item.cover_url,
        name: item.name,
        code: item.code,
        quality: item.quality,
        description: item.description,
        unit: item.unit,
        amount_per_lot: item.amount_per_lot,
        company_profile_url: item.company_profile,
        project_design_document_standard_url: item.project_design_document_standard,
        validation_and_verification_document_standard_url: item.validation_and_verification_document_standard,
        issuance_document_url: item.issuance_document,
        other_url: item.other,
        properties: item.properties,
        images: item.images,
        type: item.type,
        sdg: item.sdg,
        metas: item.metas,
      }

      if (item.metas.latitude !== undefined && item.metas.longitude !== undefined) {
        this.currentLocation = {
          lat: item.metas.latitude,
          lng: item.metas.longitude
        };
      } else  {
        this.currentLocation = {
          lat: '-6.200000',
          lng: '106.816666'
        };
      }

      this.$bvModal.show('form-commodity-modal')
    },
    preparePayload() {

      var form = new FormData();
      for (var key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) &&  this.formPayload[key] != null) {
          if (key == 'sdg') {
            continue;
          }
          form.append(key, this.formPayload[key]);
        }
      }

      // Prepare properties
      var context = this;
      this.formPayload.properties.forEach(function(item, index){
        if (item.label == '') {
          context.removeProperty(index);
        } else {
          form.append('properties[' + index + '][label]', item.label);
          form.append('properties[' + index + '][value]', item.value);
        }
      });

      this.formPayload.sdg.forEach(function(item, index){
        form.append('sdg[' + index + ']', item);
      });

      // Process images
      this.formPayload.images.forEach(function(image, index){
        if (image != null) {
          if (typeof image === 'object' && image.hasOwnProperty('file_url')) {
            form.append('images[' + index + ']', image.file_name);
          } else {
            form.append('images[' + index + ']', image);
          }
        }
      });

      // Append metas to form except latitude and longitude
      for (var key in this.formPayload.metas) {
        if (this.formPayload.metas.hasOwnProperty(key) &&  this.formPayload.metas[key] != null) {
          if (key != 'latitude' && key != 'longitude') {
            form.append('metas[' + key + ']', this.formPayload.metas[key]);
          }
        }
      }

      if (this.selectedLocation.position !== undefined && this.selectedLocation.position !== undefined) {
        form.append('metas[latitude]', this.selectedLocation.position.lat);
        form.append('metas[longitude]', this.selectedLocation.position.lng);
      }

      console.log(form)

      return form;
    },
    updateCommodity() {
      var form = this.preparePayload();
      form.append('_method', 'PATCH');
      this.isLoading = true;

      this.$http.post('/admin/commodities/' + this.editSlug, form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-commodity-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Asset successfully updated!')
        this.cleanupForm();
        this.isLoading = false;

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
        this.isLoading = false;
      })
    },
    createCommodity() {
      var form = this.preparePayload();
      this.isLoading = true;

      this.$http.post('/admin/commodities', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-commodity-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Asset successfully created')
        this.cleanupForm();
        this.isLoading = false;

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
        this.isLoading = false;
      })
    },
    deleteCommodity(slug) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Its also will delete all auction of this commodity',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.delete('/admin/commodities/' + slug)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Asset successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    downloadImportFormat() {
      this.$http.get('/admin/commodity-price/format', {
          responseType: 'blob'
      }).then(response => {
        var fileName = 'format-import-price.csv';
        FileSaver.saveAs(response.data, fileName);
      })
    },
    importPrice() {
      this.isLoadingImport = true;

      var form = new FormData();
      for (var key in this.importPricePayload) {
        if (this.importPricePayload.hasOwnProperty(key) &&  this.importPricePayload[key] != null) {
          form.append(key, this.importPricePayload[key]);
        }
      }
      this.$http.post('/admin/commodity-price/upload', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        successNotification(this, 'Success', 'Price successfully imported')
        this.isLoadingImport = false;

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
        this.isLoadingImport = false;
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
